import React, { useEffect, useState } from "react";
import logo from "../logo.png";
import { Link } from "react-router-dom";

function Footer(props) {
  const [imageSize, setImageSize] = useState({ width: "100%", height: "auto" });
  const updateImageSize = () => {
    if (window.innerWidth >= 992) {
      setImageSize({ width: "350px", height: "auto" }); // Large screens
    } else if (window.innerWidth >= 768) {
      setImageSize({ width: "250px", height: "auto" }); // Medium screens
    } else {
      setImageSize({ width: "100px", height: "auto" }); // Small screens
    }
  };

  useEffect(() => {
    updateImageSize(); // Call on component mount
    window.addEventListener("resize", updateImageSize); // Listen to window resize
    return () => window.removeEventListener("resize", updateImageSize); // Cleanup listener
  }, []);

  const [responsiveFontSize, setResponsiveFontSize] = useState("16px");

  // Adjust font size based on screen size
  useEffect(() => {
    const updateFontSize = () => {
      if (window.innerWidth >= 992) {
        setResponsiveFontSize("16px");
      } else if (window.innerWidth >= 768) {
        setResponsiveFontSize("14px");
      } else {
        setResponsiveFontSize("12px");
      }
    };

    updateFontSize(); // Call on component mount
    window.addEventListener("resize", updateFontSize); // Listen to window resize
    return () => window.removeEventListener("resize", updateFontSize); // Cleanup listener
  }, []);
  const HeadingStyle = {
    backgroundColor: "#1D3A51",
    fontFamily: "'Poppins', bold",
    color: "white",
    borderRadius: "20px",
    display: "inline-block",
    padding: "10px",
    fontSize: responsiveFontSize,
  };
  const copyrightBar = {
    backgroundColor: "#F2C232",
    textAlign: "center",
    color: "#1D3A51",
    fontSize: responsiveFontSize,
  };
  const NavTextStyle = {
    color: "#1D3A51",
    fontFamily: "'Poppins', semibold",
    fontSize: responsiveFontSize,
  };
  const NavTextStyleCurrent = {
    color: "#1D3A51",
    fontFamily: "'Poppins', bold",
    fontWeight: "bold",
    fontSize: responsiveFontSize,
  };
  const contactTextStyle = {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    marginBottom: "10px",
    color: "#1D3A51",
    fontFamily: "'Poppins', semibold",
    textAlign: "start",
    fontSize: responsiveFontSize,
  };

  return (
    <div>
      <footer className="row row-cols-2 row-cols-sm-2 row-cols-md-3 py-3 border-top">
        <div className="col mb-3 d-flex justify-content-start">
          <Link
            to="/"
            className="d-flex align-items-start mb-3 mx-3 link-body-emphasis text-decoration-none"
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                ...imageSize, // Dynamically set width and height
              }}
            />
          </Link>
        </div>
        <div className="col d-block d-md-none"></div>

        <div className="col mb-3 text-start ">
          <h6 style={HeadingStyle} className="mx-2">
            Quick Links
          </h6>
          <ul className="nav flex-column">
            <li className="nav-item fs-7">
              <Link
                className="nav-link active"
                aria-current="page"
                to="/"
                style={props.loc === "/" ? NavTextStyleCurrent : NavTextStyle}
              >
                {props.loc === "/" && (
                  <span style={{ fontWeight: "bold" }}>→ </span>
                )}
                Home
              </Link>
            </li>
            <li className="nav-item fs-7">
              <Link
                className="nav-link"
                to="/services"
                style={
                  props.loc === "/services" ? NavTextStyleCurrent : NavTextStyle
                }
              >
                {props.loc === "/services" && (
                  <span style={{ fontWeight: "bold" }}>→ </span>
                )}
                Servizi
              </Link>
            </li>
            <li className="nav-item fs-7">
              <Link
                className="nav-link"
                to="/aboutUs"
                style={
                  props.loc === "/aboutUs" ? NavTextStyleCurrent : NavTextStyle
                }
              >
                {props.loc === "/aboutUs" && (
                  <span style={{ fontWeight: "bold" }}>→ </span>
                )}
                Chi siamo
              </Link>
            </li>
            <li className="nav-item fs-7">
              <Link
                className="nav-link"
                to="/contactUs"
                style={
                  props.loc === "/contactUs"
                    ? NavTextStyleCurrent
                    : NavTextStyle
                }
              >
                {props.loc === "/contactUs" && (
                  <span style={{ fontWeight: "bold" }}>→ </span>
                )}
                Contattaci
              </Link>
            </li>
          </ul>
        </div>

        <div className="col mb-3 text-start">
          <h6 style={HeadingStyle} className="text-start">
            Contact Information
          </h6>
          <div className="d-flex flex-column align-items-start">
            <div style={contactTextStyle}>
              📞 <span>Phone: +39 342 843 9658</span>
            </div>
            <div style={contactTextStyle}>
              📧 <span>Email: aicnovellara@gmail.com</span>
            </div>
            <div style={contactTextStyle}>
              📍 <span>Address: Via Andrea Costa 15 Novellara, Re 42017</span>
            </div>
            <div style={contactTextStyle}>
              🎵 <span>Tik Tok: 360 Service</span>
            </div>
            <div style={contactTextStyle}>
              📸 <span>IG: 360 Service</span>
            </div>
          </div>
        </div>
      </footer>
      <div>
        <p className="py-3" style={copyrightBar}>
          Copyright © All rights reserved (Website Developed & Managed by Surge
          Agency)
        </p>
      </div>
    </div>
  );
}

export default Footer;
