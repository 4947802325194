import React, { useState, useEffect } from "react";
import StripeCheckout from "react-stripe-checkout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ModalForm({ isOpen, onClose, service }) {
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    uploadedDocument: "",
    comments: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [responsiveFontSize, setResponsiveFontSize] = useState("12px");

  // Adjust font size based on screen size
  useEffect(() => {
    const updateFontSize = () => {
      if (window.innerWidth >= 992) {
        setResponsiveFontSize("14px");
      } else if (window.innerWidth >= 768) {
        setResponsiveFontSize("12px");
      } else {
        setResponsiveFontSize("10px");
      }
    };

    updateFontSize(); // Call on component mount
    window.addEventListener("resize", updateFontSize); // Listen to window resize
    return () => window.removeEventListener("resize", updateFontSize); // Cleanup listener
  }, []);

  const validateForm = (details) => {
    const { firstName, lastName, email, phoneNumber, uploadedDocument } =
      details;
    return (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      email.trim() !== "" &&
      phoneNumber.trim() !== "" &&
      uploadedDocument !== ""
    );
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const newDetails = { ...userDetails };

    if (name === "uploadedDocument") {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        newDetails[name] = {
          name: file.name,
          type: file.type,
          data: event.target.result,
        };
        setUserDetails(newDetails);
        setIsFormValid(validateForm(newDetails));
      };

      reader.readAsDataURL(file);
    } else {
      newDetails[name] = e.target.value;
      setUserDetails(newDetails);
      setIsFormValid(validateForm(newDetails));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      toast.error("Please fill in all required fields");
      return;
    }
    console.log(userDetails);
  };

  const MakePayment = async (token) => {
    const body = {
      token,
      userDetails,
      product: {
        price: service.price,
        name: service.title,
      },
    };

    try {
      const response = await toast.promise(
        fetch(`http://localhost:5000/payment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }),
        {
          pending: "Processing payment...",
          success: "Payment successful! 🎉",
          error: "Payment failed. Please try again 🚫",
        }
      );

      if (!response.ok) {
        throw new Error("Payment failed");
      }

      await response.json();
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      console.error("Payment error:", error);
    }
  };

  if (!isOpen) return null;

  const SubHeaderStyle = {
    color: "#F2C232",
    fontFamily: "'Druk Text', sans-serif",
    fontWeight: "400",
  };
  const HeaderStyle = {
    color: "#1D3A51",
    fontFamily: "'Druk Text', sans-serif",
    fontWeight: "400",
    fontSize: responsiveFontSize,
  };
  const ServiceStyle = {
    color: "white",
    fontFamily: "'Druk Text', sans-serif",
    fontWeight: "400",
  };
  const ServiceStyle2 = {
    color: "#1D3A51",
    fontFamily: "'Druk Text', sans-serif",
    fontWeight: "400",
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <div
        className="modal fade show"
        tabIndex="-1"
        style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "400px" }}
        >
          <div className="modal-content">
            <div
              className="modal-header d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#1D3A51" }}
            >
              <h6 className="modal-title" style={SubHeaderStyle}>
                COMPLETA IL TUO ACQUISTO
              </h6>
            </div>
            <form onSubmit={handleSubmit}>
              <div
                className="modal-body"
                style={{ backgroundColor: "#F2C232" }}
              >
                <h5
                  className="d-flex justify-content-center"
                  style={ServiceStyle}
                >
                  {service.title}
                </h5>
                <h5
                  className="d-flex justify-content-center"
                  style={ServiceStyle2}
                >
                  €{service.price}
                </h5>
                <div className="mb-3">
                  <label
                    className="form-label d-flex justify-content-start px-2"
                    style={HeaderStyle}
                  >
                    Nome di battesimo
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Enter your first name"
                    className="form-control"
                    id="firstName"
                    autoComplete="off"
                    value={userDetails.firstName}
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label
                    className="form-label d-flex justify-content-start px-2"
                    style={HeaderStyle}
                  >
                    Cognome
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    placeholder="Enter your last name"
                    id="lastName"
                    autoComplete="off"
                    value={userDetails.lastName}
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label
                    className="form-label d-flex justify-content-start px-2"
                    style={HeaderStyle}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter your email"
                    id="email"
                    autoComplete="off"
                    value={userDetails.email}
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label
                    className="form-label d-flex justify-content-start px-2"
                    style={HeaderStyle}
                  >
                    Numero
                  </label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    className="form-control"
                    placeholder="Enter your phone number"
                    id="phoneNumber"
                    autoComplete="off"
                    value={userDetails.phoneNumber}
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label
                    className="form-label d-flex justify-content-start px-2"
                    style={HeaderStyle}
                  >
                    Carica documento
                  </label>
                  <input
                    type="file"
                    placeholder="Upload Document"
                    name="uploadedDocument"
                    className="form-control"
                    id="uploadedDocument"
                    autoComplete="off"
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label
                    className="form-label d-flex justify-content-start px-2"
                    style={HeaderStyle}
                  >
                    Commenti
                  </label>
                  <textarea
                    name="comments"
                    className="form-control"
                    placeholder="Enter your comment"
                    id="comments"
                    autoComplete="off"
                    value={userDetails.comments}
                    onChange={handleInput}
                    rows={3}
                  ></textarea>
                </div>
              </div>
              <div
                className="modal-footer"
                style={{ backgroundColor: "#1D3A51" }}
              >
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={onClose}
                >
                  Close
                </button>
                {isFormValid && (
                  <button
                    type="submit"
                    className="btn"
                    style={{ backgroundColor: "#F2C232" }}
                  >
                    <StripeCheckout
                      stripeKey={process.env.REACT_APP_KEY}
                      token={MakePayment}
                      name="YOUR CHECKOUT CART"
                      amount={service.price * 100}
                      currency="eur"
                    >
                      Effettua il pagamento
                    </StripeCheckout>
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalForm;
