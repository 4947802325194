import React, { useState, useEffect } from "react";

function AboutUsPoints(props) {
  const SubHeaderStyle = {
    color: "#1D3A51",
    fontFamily: "'Poppins'",
    textAlign: "left",
    fontWeight: "400",
    fontSize: "100px",
  };
  const [responsiveFontSize, setResponsiveFontSize] = useState("12px");

  // Adjust font size based on screen size
  useEffect(() => {
    const updateFontSize = () => {
      if (window.innerWidth >= 992) {
        setResponsiveFontSize("16px");
      } else if (window.innerWidth >= 768) {
        setResponsiveFontSize("14px");
      } else {
        setResponsiveFontSize("10px");
      }
    };

    updateFontSize(); // Call on component mount
    window.addEventListener("resize", updateFontSize); // Listen to window resize
    return () => window.removeEventListener("resize", updateFontSize); // Cleanup listener
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-1">
          <img src={props.imgUrl} alt="Bootstrap" width="60px" />
        </div>
        <div
          className="col-11 d-flex align-items-center text-align-center px-5"
          style={SubHeaderStyle}
        >
          <h3 style={{ fontSize: responsiveFontSize }}>{props.Desc}</h3>
        </div>
      </div>
    </div>
  );
}

export default AboutUsPoints;
