import { useState } from "react";
import "./App.css";
import AboutUs from "./Components/AboutUs.js";
import ContactInfo from "./Components/ContactInfo.js";
import Cart from "./Components/Cart.js";
import Footer from "./Components/Footer.js";
import Homepage from "./Components/Homepage.js";
import NavBar from "./Components/NavBar.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Services from "./Components/Services.js";

function AppContent() {
  const loc = useLocation();
  const [cartItems, setCartItems] = useState([]);

  const addToCart = (item) => {
    // Check if item already exists in cart
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
    debugger;
    if (!existingItem) {
      setCartItems([...cartItems, item]);
    }
  };

  const removeFromCart = (itemId) => {
    setCartItems(cartItems.filter((item) => item.id !== itemId));
  };

  return (
    <>
      <NavBar loc={loc.pathname} />
      {/* <Cart cartItems={cartItems} removeFromCart={removeFromCart} /> */}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Homepage />
            </>
          }
        />
        <Route
          path="/aboutUs"
          element={
            <>
              <AboutUs />
              <Footer loc={loc.pathname} />
            </>
          }
        />
        <Route
          path="/contactUs"
          element={
            <>
              <ContactInfo />
              <Footer loc={loc.pathname} />
            </>
          }
        />
        <Route
          path="/services"
          element={
            <>
              <Services addToCart={addToCart} />
              <Footer loc={loc.pathname} />
            </>
          }
        />
      </Routes>
    </>
  );
}
function App() {
  return (
    <div className="App">
      <Router>
        <AppContent />
      </Router>
    </div>
  );
}

export default App;
