import React, { useState, useEffect } from "react";
import logo from "../logo.png";
import { Link } from "react-router-dom";
import "./NavBar.css";

function NavBar(props) {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const NavTextStyle = {
    color: "white",
    fontFamily: "'Poppins', semibold",
    fontSize: "15px",
  };

  const NavTextStyleHover = {
    backgroundColor: "#1D3A51",
    fontFamily: "'Poppins', semibold",
    color: "white",
    borderRadius: "15px",
    fontSize: "15px",
  };

  const NavBarStyle = {
    backgroundColor: "#F2C232",
    color: "white",
    borderBottomLeftRadius: "25px",
    borderBottomRightRadius: "25px",
    padding: "4px",
  };

  const [responsivePadding, setResponsivePadding] = useState("30%");
  const [responsiveMargin, setResponsiveMargin] = useState("50px");
  const [responsiveRadius, setResponsiveRadius] = useState("15px");

  // Adjust font size based on screen size
  useEffect(() => {
    const updatePadding = () => {
      if (window.innerWidth >= 992) {
        setResponsivePadding("30%");
        setResponsiveMargin("50px");
        setResponsiveRadius("15px");
      } else if (window.innerWidth >= 768) {
        setResponsivePadding("0%");
        setResponsiveMargin("30px");
        setResponsiveRadius("0px");
      } else {
        setResponsivePadding("0%");
        setResponsiveMargin("20px");
        setResponsiveRadius("0px");
      }
    };

    updatePadding(); // Call on component mount
    window.addEventListener("resize", updatePadding); // Listen to window resize
    return () => window.removeEventListener("resize", updatePadding); // Cleanup listener
  }, []);
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg"
        style={{
          "--bs-navbar-padding-x": "0",
          "--bs-navbar-padding-y": "0",
        }}
      >
        <div
          className="container-fluid d-flex align-items-center"
          style={{
            "--bs-gutter-x": "0",
            "--bs-gutter-y": "0",
          }}
        >
          <Link className="navbar-brand me-auto" to="/">
            <img
              src={logo}
              alt="Bootstrap"
              width="70"
              height="44"
              style={{ marginLeft: responsiveMargin }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
            id="navbarNav"
            style={{
              backgroundColor: "#F2C232",
              borderBottomLeftRadius: responsiveRadius,
              padding: "10px",
              marginLeft: responsivePadding,
            }}
          >
            <ul className="navbar-nav ms-auto">
              <li className="nav-item mx-4 fs-4">
                <Link
                  className="nav-link active px-5"
                  aria-current="page"
                  to="/"
                  onClick={handleNavCollapse}
                  style={props.loc === "/" ? NavTextStyleHover : NavTextStyle}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item mx-4 fs-4">
                <Link
                  className="nav-link px-5"
                  to="/services"
                  onClick={handleNavCollapse}
                  style={
                    props.loc === "/services" ? NavTextStyleHover : NavTextStyle
                  }
                >
                  Servizi
                </Link>
              </li>
              <li className="nav-item mx-4 fs-4">
                <Link
                  className="nav-link px-5"
                  to="/aboutUs"
                  onClick={handleNavCollapse}
                  style={
                    props.loc === "/aboutUs" ? NavTextStyleHover : NavTextStyle
                  }
                >
                  Chi siamo
                </Link>
              </li>
              <li className="nav-item mx-4 fs-4">
                <Link
                  className="nav-link px-5"
                  to="/contactUs"
                  onClick={handleNavCollapse}
                  style={
                    props.loc === "/contactUs"
                      ? NavTextStyleHover
                      : NavTextStyle
                  }
                >
                  Contattaci
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
