import React, { useState, useEffect } from "react";
import ModalForm from "./ModalForm";

function ServicesCard({ id, imgUrl, title, description, price, onAddToCart }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [responsiveFontSize, setResponsiveFontSize] = useState("12px");

  // Adjust font size based on screen size
  useEffect(() => {
    const updateFontSize = () => {
      if (window.innerWidth >= 992) {
        setResponsiveFontSize("12px");
      } else if (window.innerWidth >= 768) {
        setResponsiveFontSize("10px");
      } else {
        setResponsiveFontSize("8px");
      }
    };

    updateFontSize(); // Call on component mount
    window.addEventListener("resize", updateFontSize); // Listen to window resize
    return () => window.removeEventListener("resize", updateFontSize); // Cleanup listener
  }, []);

  const cardStyling = {
    backgroundColor: "#F2C232",
    maxWidth: "540px",
    height: "100%",
  };

  const priceCol = {
    color: "#F2C232",
    textAlign: "left",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "900",
  };

  const handleBuyNow = () => {
    setSelectedService({ id, title, price });
    setShowModal(true); // Open the modal
  };

  return (
    <>
      <div className="card h-100" style={cardStyling}>
        <div className="row g-0 h-100 flex-row">
          <div className="col-3 col-md-5 d-flex justify-content-center align-items-center p-2">
            <img
              src={imgUrl}
              alt={title}
              className="img-fluid"
              style={{
                borderRadius: "10px",
                maxWidth: "100%",
                maxHeight: "200px",
                width: "auto",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </div>

          <div className="col-9 col-md-7 h-100">
            <div
              className="bg-white h-100 d-flex flex-column"
              style={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <div className="p-3 flex-grow-1">
                <h6 className="card-title mb-2 text-start">{title}</h6>
                <p
                  className="card-text mb-3 text-start"
                  style={{
                    fontSize: responsiveFontSize,
                    maxHeight: "120px",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                  }}
                >
                  {description}
                </p>
                <h2 style={priceCol}>€{price}</h2>
              </div>
              <button
                onClick={handleBuyNow}
                className="btn py-1"
                style={{
                  borderRadius: 10,
                  backgroundColor: "#1D3A51",
                  color: "white",
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: "1rem",
                  width: "100%",
                }}
              >
                Acquisto Ora
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* ModalForm Component */}
      <ModalForm
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        service={selectedService}
      />
    </>
  );
}

export default ServicesCard;
